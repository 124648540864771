










































































































































import Profile from '@/connector/Profile.vue';
import BaseDataTable from '@/components/BaseDataTable.vue';
import { Component, Vue } from 'vue-property-decorator';
import TransactionService from '@/services/TransactionService';
import moment from 'moment';
import Auth from '@/connector/Auth.vue';

const transactionService = new TransactionService();

@Component({
  components: {
    Profile,
    BaseDataTable,
    Auth,
  },
})
export default class WithdrawPage extends Vue {
  public amount: number = 0;

  public loading: boolean = false;

  public async requestWithdraw() {
    try {
      this.loading = true;
      const { data } = await transactionService.requestWithdraw({ amount: this.amount });
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: data.message || 'ดำเนินการเรียบร้อยแล้ว', error: false });
      (this.$refs.WITHDRAW_TABLE as Vue & { refreshData: () => void }).refreshData();
      this.amount = 0;
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
      this.loading = false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public formatDate(time: number) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }

  // eslint-disable-next-line class-methods-use-this
  get withdrawListUrl() {
    return `${process.env.VUE_APP_API_ENDPOINT}/transactions/withdraw`;
  }
}
